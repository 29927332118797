// src/data.js

export const skills = [
    {
      title: "Creative Direction",
      description:
        "Leading teams to align vision, strategy, and aesthetics for impactful visual communication.",
      icon: "./images/ico-creative-direction.svg",
    },
    {
        title: "Design Systems",
        description:
            "Establishing rules, components, and documentation to maintain visual and functional consistency in design.",
        icon: "./images/ico-design-system.svg",
    },
    {
        title: "UI Design",
        description:
            "Designing digital interfaces with typography, layout, colour, and interactions for intuitive user experience.",
        icon: "./images/ico-ui-design.svg",
    },
    {
        title: "Front end development",
        description:
            "Implementing website designs using HTML, CSS, and JavaScript for responsive, functional, and accessible interfaces.",
        icon: "./images/ico-development.svg",
    },
    {
        title: "Graphic Design",
        description:
            "Proficiency in visual composition, typography, colour theory, and illustration to create functional design.",
        icon: "./images/ico-graphic-design.svg",
    },
    {
        title: "Branding",
        description:
            "Expertise in logo design, colour theory, typography, and visual identity to establish a business's identity.",
        icon: "./images/ico-branding.svg",
    }  
];

export const experiences = [
    {
        logo: "./images/logo-bmo.svg",
        company: "BMO (AIR MILES)",
        title: "Principal UI Designer",
        time: "June 2023 - Present",
        location: "Toronto, Canada",
    },
    {
        logo: "./images/logo-loyalty-one.svg",
        company: "Loyalty One (AIR MILES)",
        title: "Senior UI Designer",
        time: "January 2023 - June 2023",
        location: "Toronto, Canada",
    },
    {
        logo: "./images/logo-porter.svg",
        company: "Porter Airlines",
        title: "Senior UI Designer",
        time: "March 2022 - December 2022",
        location: "Toronto, Canada",
    },
    {
        logo: "./images/logo-george-brown.svg",
        company: "George Brown College",
        title: "UX/UI Instructor",
        time: "October 2021 - December 2022",
        location: "Toronto, Canada",
    },
    {
        logo: "./images/logo-red.svg",
        company: "RED Academy",
        title: "UX/UI Instructor",
        time: "January 2019 - July 2019",
        location: "Toronto, Canada",
    },
    {
        logo: "./images/logo-glk.svg",
        company: "Good Lookin Kids Inc.",
        title: "Founder / Creative Director",
        time: "Founded 2012",
        location: "Toronto, Canada",
    }
];

export const projects = [
    {
        key: "project1",
        title: "AIR MILES",
        skills: "UI Design",
        thumb: "./images/thumb-air-miles.jpg",
        hero: "./images/hero-air-miles.jpg",
        description: `
        <span class="subtitle">My Role</span>
        <p class="large">As a senior UI designer for Air Miles, I have the privilege of crafting visually captivating and user-friendly interfaces that enhance the overall experience for our members. By blending aesthetic appeal with seamless functionality, my role involves designing intuitive interfaces that make it easy for users to track their miles, explore exciting offers, and redeem rewards effortlessly.</p>
        
        <p class="large"><a href="https://airmiles.ca" target="_blank">Visit Website</a></p>
        
        <div class="rule"></div>
        <div class="columns">
            <div class="col two_third">
                <span class="subtitle">My Approach</span>
                <p>Collaborating with a talented team, I strive to create an engaging digital platform that reflects Air Miles' commitment to delivering exceptional value and unforgettable experiences to their loyal customers. I achieved this by developing a global design system to unify all properties.</p>
            </div>
            <div class="col one_third">
                <span class="subtitle">Skills</span>
                <p>UI Design<br />
                Design System Architecture<br />
                Prototyping<br />
                REACT Development</p>
            </div>
        </div>

        <div class="clear md"></div>

        <div class="columns">
            <div class="col one_half">
                <img src="images/airmiles-1.jpg" alt="AIR MILES" />
            </div>
            <div class="col one_half">
            <img src="images/airmiles-2.jpg" alt="AIR MILES" />
            </div>
        </div>

        <div class="clear md"></div>

        <h3>Building the Design System</h3>
        <p>Collaborating with developers, product owners, analytics, and marketers, I developed a fully functioning design system. The design system is composed of a series of designed components, styles, and tokens in Figma. Additionally, there is a code base with a library of matching patterns and styles, as well as a microsite (which I designed and developed in REACT) that serves as a "source of truth."</p>

        <div class="clear sm"></div>

        <img src="images/am-ds-1.jpg" alt="AIR MILES DS" class="border" />

        <div class="clear sm"></div>

        <img src="images/am-ds-2.jpg" alt="AIR MILES DS" class="border" />

        <div class="clear sm"></div>
        
        <img src="images/am-ds-3.jpg" alt="AIR MILES DS" class="border" />

        <div class="clear md"></div>

        <h3>The evolution of the design system</h3>
        <p>I played a pivotal role in revitalizing the AIR MILES user interface (UI) by defining design patterns and foundational elements across various properties and applications. The ever growing library of components were designed to match brand guidelines and to meet accessibility standards.</p>
        <p>My role in defining the AIR MILES design systems became more important than ever during the brand's refresh. I established a global set of rules and guidelines aligned with a CMS platform, enabling the quick and efficient rollout of new and refreshed pages. This, combined with the tokenization of foundational elements allowed for the alignment with other platforms (web, app, marketing etc).</p>        
        
        <img src="images/am-ds-4.jpg" alt="AIR MILES DS" class="border" />

        `
    },
    {
        key: "project2",
        title: "Porter Airlines",
        skills: "UI Design",
        thumb: "./images/thumb-porter.jpg",
        hero: "./images/hero-porter.jpg",
        description: `
        <span class="subtitle">My Role</span>
        <p class="large">As a UI designer, I had the opportunity to work with a talented team of UI and UX designers to develop a comprehensive design system. One of my primary responsibilities was to use Figma to create a library of reusable components and saved styles that could be easily implemented throughout the website. This allowed us to work efficiently and maintain consistency across all design elements.</p>
        
        <p class="large"><a href="https://www.flyporter.com/en-ca/info/porter-experience" target="_blank">Visit Website</a></p>
        
        <div class="rule"></div>
        <div class="columns">
            <div class="col two_third">
                <span class="subtitle">My Approach</span>
                <p>In collaboration with the marketing team, I helped to create hero banners and endcaps that utilized an established colour palette and illustrative style. I ensured that the complexity around how the heroes were composed was fully responsive over 4 media breakpoints, providing a seamless user experience across all devices. Additionally, I was able to refresh parts of the website in an agile way, enhancing the UI in ways that didn't stray from the current experience but enough to create a clear visual improvement.</p>
            </div>
            <div class="col one_third">
                <span class="subtitle">Skills</span>
                <p>UI Design<br />
                Illustration<br />
                Development<br />
                UX Research & Testing</p>
            </div>
        </div>

        <div class="clear md"></div>

        <div class="columns">
            <div class="col one_half">
                <img src="images/porter-1.jpg" alt="Porter Airlines" />
            </div>
            <div class="col one_half">
            <img src="images/porter-2.jpg" alt="Porter Airlines" />
            </div>
        </div>

        <div class="clear md"></div>

        <h3>The Porter Experience</h3>
        <p>My biggest accomplishment during my time as a senior UI designer for Porter Airlines was working closely with the development team to produce an interactive experience highlighting the Porter Experience. With the launch of the new fleet of Embraer E195 Jets, which would be flying out of Pearson airport, it was crucial to convey this message in an experience that was impactful. I provided layouts, working prototypes, and brainstormed front-end development solutions to create a unique and engaging user experience. The final product was a true collaboration between design and development.</p>

        <div class="clear md"></div>

        <img src="images/porter-3.jpg" alt="Porter Airlines" />

        <div class="clear md"></div>

        <img src="images/porter-4.jpg" alt="Porter Airlines" />

        <div class="clear md"></div>

        <img src="images/porter-5.jpg" alt="Porter Airlines" />
        
        <div class="clear md"></div>

        <img src="images/porter-6.jpg" alt="Porter Airlines" />

        <div class="clear md"></div>

        <h3>Hero's and Endcaps</h3>
        <p>I designed dynamic hero banners and end caps for the Porter Airlines website as part of a UI update. Using my technical programming skills, I created multi-layered elements that meet accessibility standards and are responsive across various screen sizes. These elements adapt smoothly to different devices, ensuring a seamless user experience. I also crafted matching end caps, maintaining visual consistency with the hero banners, thus contributing to a unified look throughout the site. This demonstrates my ability to merge design innovation and technical expertise to create a cohesive and accessible digital platform.</p>
        
        <div class="clear md"></div>

        <img src="images/porter-7.jpg" alt="Porter Airlines" />

        <div class="clear md"></div>

        <img src="images/porter-8.jpg" alt="Porter Airlines" />

        <div class="clear md"></div>

        <img src="images/porter-9.jpg" alt="Porter Airlines" />

        <div class="clear md"></div>

        <img src="images/porter-10.jpg" alt="Porter Airlines" />
        `
    },
    {
        key: "project3",
        title: "SickKids CCMH",
        skills: "UI / UX / Development",
        thumb: "./images/thumb-sickkids.jpg",
        hero: "./images/hero-sick-kids.jpg",
        description: `
        <span class="subtitle">The Challenge</span>
        <p class="large">Redesigning SickKids' website posed several challenges, including its dated and templated look, unclear core sections, poor accessibility, and mobile optimization. All pages used the same template, which hindered the establishment of a hierarchy. To improve the site's overall functionality and accommodate future growth, the redesign called for a flexible and expandable approach. Nonetheless, I was driven to create a visually appealing and modern website for SickKids.</p>
        
        <p class="large"><a href="https://sickkidscmh.ca" target="_blank">Visit Website</a></p>

        <div class="rule"></div>
        
        <div class="columns">
            <div class="col two_third">
                <span class="subtitle">My Approach</span>
                <p>The website required improvement, but I aimed to prioritize accessibility (AODA AA) while also creating a modern and visually appealing site. To avoid a sterile look, I aimed to differentiate the core areas with color and shape. Additionally, I identified and distinguished the organization's three unique departments while enhancing the mobile experience.</p>
            </div>
            <div class="col one_third">
                <span class="subtitle">Skills</span>
                <p>UI Design<br />
                UX Consultation<br />
                Development<br />
                AODA Compliance</p>
            </div>
        </div>

        <div class="clear md"></div>

        <h2 style="padding-bottom:0px;">Process</h2>
        <p class="large">Every business has a story and as a designer, my goal is to help them tell it. To do this, I use a three-step process.</p>

        <div class="clear sm"></div> 
        
        <div class="columns">
            <div class="col one_third">
                <h3 class="small">Discovery</h3>
                <p>Discovery is a crucial phase in any design project, and a good starting point is to identify users and pain points. By understanding who the users are, their needs and challenges, as a designer I can create user-centered solutions that address the pain points and improve the overall user experience.</p>
            </div>
            <div class="col one_third">
                <h3 class="small">Strategy</h3>
                <p>In the strategy phase, I provide evidence-based solutions to address the user pain points and meet the project objectives. By leveraging research insights, I can create a comprehensive strategy that outlines the project's vision, goals, and tactics to achieve a successful outcome.</p>
            </div>
            <div class="col one_third">
                <h3 class="small">Execution</h3>
                <p>During the execution phase, I design layouts and interactive prototypes based on the insights gathered during discovery and strategy. By translating research into design solutions, I ensure that the end product aligns with the project goals and provides an intuitive and engaging user experience.</p>
            </div>
        </div>

        <div class="clear md"></div>

        <h2>Discovery</h2>

        <div class="columns">
            <div class="col two_third">
                <p>During the discovery phase, I collaborated with UX strategists and a copywriter to create three user personas with different pain points and needs. To present our assumptions to the client, I created visual representations of each persona. These representations were based on key aspects of the personas, such as their technology usage and objectives, derived from their biographical, occupational, and personal traits. The personas served as a foundation for the project, enabling the design and development teams to create a user-centered website that met the needs of the target audience.</p>
            </div>
            <div class="col one_third">
                <img src="images/ccmh-2.jpg" alt="SickKids CCMH" />
            </div>
        </div>

        <div class="clear md"></div>

        <h2>Strategy</h2>

        <div class="columns">
            <div class="col two_third">
                <p>I verified the user personas, confirming their pain points and needs, before designing a detailed and comprehensive IA document. The IA was visually designed to identify user flows based on our user personas, ensuring they could access the required content with minimal effort. To assist with this, I included a legend that identified page types, content types, and navigation paths.</p> 
                <p>In addition, I included pertinent information about specific pages alongside the corresponding pages. The IA document provided a clear plan for design and development, enabling me to create a website that was user-friendly and efficient in meeting the needs of the target audience.</p>
            </div>
            <div class="col one_third">
            <img src="images/ccmh-3.jpg" alt="SickKids CCMH" />
            </div>
        </div>
        
        <div class="clear md"></div>

        <h2>Execution</h2>

        <img src="images/ccmh-5.jpg" alt="SickKids CCMH" />

        <div class="clear md"></div>

        <p>I worked closely with the brand team to develop a web brand guide of colour gradients, based on the existing brand colour palette used in print campaigns, but not in the previous website. One of the biggest challenges was ensuring that colour contrast was always considered to meet accessibility standards. To achieve this, I carefully selected colours with high contrast and developed a detailed colour system to ensure consistency throughout the website and any future digital mediums. From a typographic perspective, I utilized Google's web font library and selected "Libre Franklin," a classic sans serif font based on "Franklin Gothic." The chosen typeface was selected for its legibility, as well as its wide range of weights, from thin to black, enabling me to create a variety of visual hierarchies and establish a cohesive design aesthetic for the website.

        <div class="clear md"></div>

        <h2>Creating visual hierarchy between departments</h2>

        <div class="columns">
            <div class="col two_third">
                <p>To ensure a clear distinction between the three core departments of CCMH, I created custom experiences that matched their unique visual branding while still being complementary to the overall CCMH brand. This was achieved by utilizing the same color palette and shapes throughout all parent and child pages of each department. To maintain easy navigation, the header and footer remained consistent across the site, while a fixed on-scroll sub-navigation was added to parent and child pages specific to their department. This approach created a visual hierarchy between departments while maintaining consistency and accessibility throughout the site.</p>
            </div>
            <div class="col one_third">
                <img src="images/ccmh-4.jpg" alt="SickKids CCMH" />
            </div>
        </div>

        <div class="clear md"></div>

        <h2>Accessibility</h2>

        <div class="columns">
            <div class="col two_third">
                <p>The redesign of the website for SickKids had to prioritize accessibility. The site had to pass AODA AA standards while also maintaining its design integrity. The challenge was to make the site accessible without making it look sterile like a government website, which are often unusable due to the lack of hierarchy. To achieve this, I utilized various tools, including Google's Lighthouse, which yielded a perfect accessibility score. Additionally, I implemented a discrete yet effective tool, the Universal Accessibility Icon, which allowed overrides and ensured the site was accessible to all users. The result was a website that was visually appealing and accessible to all users, regardless of their needs.</p>
            </div>
            <div class="col one_third">
                <img src="images/ccmh-6.jpg" alt="SickKids CCMH" />
            </div>
        </div>
        
        <div class="clear md"></div>

        <h2>Success Story</h2>

        <img src="images/ccmh-7.jpg" alt="SickKids CCMH" />

        <div class="clear md"></div>

        <p>The redesign of the CCMH website was more than just a visual upgrade - it was a success story that resulted in securing funding (The PR firm that I collaborated with on the project) developed a PR strategy that showcased the updated website and its enhanced features to potential donors. As a result, in September 2021, CCMH received a generous donation from Garry Hurvitz, an accomplished entrepreneur and philanthropist in Toronto. Although it's impossible to know for sure, it's gratifying to think that the hard work and effort put into the redesign of the CCMH website played a role in bringing attention to the organization and helped secure the funding it needed to continue its vital work.</p>
        `
    },
    {
        key: "project4",
        title: "Weekend at the Cottage",
        skills: "UI / UX / Development",
        thumb: "./images/thumb-watc.jpg",
        hero: "./images/hero-watc.jpg",
        description: `
        <span class="subtitle">The Challenge</span>
        <p class="large">Weekend at the Cottage has an extensive library of recipes and lifestyle posts. As time passed from the initial launch of the website, technologies have changed and new features had been added, all working within the existing framework.</p>
        
        <p class="large"><a href="https://weekendatthecottage.com" target="_blank">Visit Website</a></p>
        
        <div class="rule"></div>
        <div class="columns">
            <div class="col two_third">
                <span class="subtitle">My Approach</span>
                <p>I first developed a website for WATC back in 2013. Since then their user base and overall traffic to the website has significantly increased. My goal with bringing WATC into 2020 was to develop a fresh new look that was user-centric while simultaneously creating a strategy to generate advertising revenue.</p>
            </div>
            <div class="col one_third">
                <span class="subtitle">Skills</span>
                <p>UI Design<br />
                Information Architecture<br />
                Development<br />
                SEO research</p>
            </div>
        </div>

        <div class="clear md"></div>

        <div class="columns">
            <div class="col one_half">
                <img src="images/watc-1.jpg" alt="Weekend at the Cottage" />
            </div>
            <div class="col one_half">
            <img src="images/watc-2.jpg" alt="Weekend at the Cottage" />
            </div>
        </div>

        <div class="clear md"></div>

        <h3>Making the content accessible</h3>
        <p>After doing some research and having discussions with potential users, a common complaint about recipe blogs is the amount of effort required to get to the actual recipe. Nik, the founder of Weekend of the Cottage does an incredible job of writing enticing instructions with peppered background details, but for some users they just want the details.</p>
        <p>My strategy was to streamline the process to have “quick-action” tools to easily jump directly to the recipe, as well as tools to print out recipe sheets that have been simplified. To meet the revenue goals of the website, I used creative techniques to keep ad space visible to users even if they skip through the content.</p>

        <div class="clear md"></div>

        <img src="images/watc-3.jpg" alt="Weekend at the Cottage" />

        <div class="clear md"></div>

        <img src="images/watc-4.jpg" alt="Weekend at the Cottage" />

        <div class="clear md"></div>

        <h3>Adaptive Design</h3>
        <p>Mobile-first design is nothing new. The idea that you should design a website to work first on a mobile device brought light to the concept of responsive design. Responsive design is when containers scale, columns stack, and menus collapse. This ensures that your designs work across all screen sizes.</p>
        <p>Where adaptive design comes into play is when objects are added or removed, plus layouts change in an unorthodox non-responsive way. Adaptive design doesn't simply move things to fit on a smaller screen, it creates a layout that is suited for the device. I took full advantage of this technique when designing and developing WATC.</p>
        <p>Utilizing high-yielded UX techniques from popular apps I provided an app-like experience for mobile users via touch interactions and dynamic grids.</p>
        
        <div class="clear md"></div>

        <img src="images/watc-5.gif" alt="Weekend at the Cottage" />

        <div class="clear md"></div>

        <h3>Beyond the traditional website</h3>
        <p>Lastly, as part of my strategy to improve the overall reach of the website I looked at modern technologies and future applications. While the website is fully optimized for search engine crawlers (with the help of WATC's incredibly written content), I wanted to go even further. I looked at how users access the type of content that WATC would be producing. One often overlooked access point is through Google Assistant. With the rise of smart home tech, I was able to easily use the framework that I built to have the meta content ready to be read by a variety of platforms, which means the recipes are read aloud through Google Home or Alexa.</p>

        <div class="clear md"></div>

        <img src="images/watc-6.jpg" alt="Weekend at the Cottage" />
        `
    },
    {
        key: "project5",
        title: "Tailored Website Designs",
        skills: "UX / UI / Development",
        thumb: "./images/thumb-web-design.jpg",
        hero: "./images/hero-web-design.jpg",
        description: `
        <span class="subtitle">Versatile Design Solutions</span>
        <p class="large">A collection of website designs showcasing my ability to create tailored solutions that align with diverse brand identities while ensuring seamless and optimized user experiences. With over a decade of experience designing and developing websites, I have developed an adaptive style that allows me to cater to the needs of any industry. Here are some of those projects.</p>

        <div class="rule"></div>

        <img src="images/client-ast.jpg" alt="Alexander Showcase Theatre" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">Alexander Showcase Theatre</h2>
        <p>Alexander Showcase Theatre is a vibrant, not-for-profit community theatre group based in Toronto, dedicated to producing high-quality, fully staged Broadway musicals, operettas, plays, and choral concerts.</p>
        <p>I had the opportunity to design and develop a website to showcase their past and present shows in a modern and engaging way.</p>
        <a href="https://alexandershowcasetheatre.com/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>

        <div class="clear lg"></div>

        <img src="images/client-originate.jpg" alt="Originate" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">Originate</h2>
        <p>Originate Developments Inc. is a Toronto-based real estate developer focused on creating modern, high-quality urban residential communities. They specialize in transforming overlooked areas into vibrant, livable spaces with innovation and attention to detail.</p>
        <p>I designed their website, originate.ca, to highlight their originality and craftsmanship. Its clean design, intuitive navigation, and striking visuals create an engaging experience that reflects their commitment to excellence.</p>
        <a href="https://originate.ca/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>

        <div class="clear lg"></div>

        <img src="images/client-osheas.jpg" alt="Osheas" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">Osheas Irish Snug</h2>
        <p>T.J. O'Shea's Irish Snug is a welcoming pub in Toronto's Long Branch neighborhood, known for its traditional Irish fare, modern dishes, and live music on weekends.</p>
        <p>I designed their website, osheasirishsnug.com, to capture the pub's warm atmosphere. The standout feature is an easy-to-use menu page, which also serves as their primary in-house menu, ensuring patrons can quickly browse offerings and specials.</p>
        <a href="https://osheasirishsnug.com/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>

        <div class="clear lg"></div>

        <img src="images/client-iw.jpg" alt="Idea Workshop" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">Idea Workshop</h2>
        <p>Idea Workshop is a Toronto-based international communications firm specializing in public relations, marketing, branding, interactive, and design services. I have been collaborating with Idea Workshop for over a decade, helping them with their web work.</p>
        <p>I've also designed and developed their website, which showcases all of their work.</p>
        <a href="https://ideaworkshop.ca/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>
        
        <div class="clear lg"></div>

        <img src="images/client-breakthrough.jpg" alt="Breakthrough Autism" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">Breakthrough Autism</h2>
        <p>Breakthrough Autism is a Toronto organization helping children with autism thrive through personalized ABA therapy and family involvement.</p>
        <p>In collaboration with Idea Workshop, I designed their website, breakthroughautism.ca, with a clean, user-friendly layout that highlights their services and commitment to supporting families.</p>
        <a href="https://breakthroughautism.ca/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>

        <div class="clear lg"></div>

        <img src="images/client-holiday.jpg" alt="Holiday" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">Holiday Films</h2>
        <p>Holiday Films is a leading Toronto-based production company, representing talented directors worldwide and delivering creative, high-quality film projects.</p>
        <p>I designed their website, holidayfilms.tv, with a sleek and simple layout that highlights their directors and projects. The site is easy to navigate, making their work the clear focus.</p>
        <a href="https://holidayfilms.tv/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>

        <div class="clear lg"></div>

        <img src="images/client-fairmarc.jpg" alt="Fairmarc" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">Fairmarc Psychology Centre</h2>
        <p>Fairmarc Psychology Centre is a private clinic in Toronto offering evidence-based treatments like Cognitive Behavioural Therapy (CBT) and Dialectical Behaviour Therapy (DBT). They also provide psychoeducational assessments and engaging workshops to support mental health.</p>
        <p>I designed their website, fairmarc.ca, to be simple and easy to use. The clean layout helps visitors quickly find information about their services, team, and how to get in touch. This design makes it straightforward for people to learn about and access the support they need.</p>
        <a href="https://fairmarc.ca/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>

        <div class="clear lg"></div>

        <img src="images/client-crown.jpg" alt="Crown Content class="border" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">Crown Content</h2>
        <p>Crown Content is a Toronto-based company that specializes in creating engaging digital content, focusing on motion graphics and interactive experiences. They work with businesses to bring dynamic visuals to life, enhancing brand storytelling and audience engagement.</p>
        <p>I designed their website, crowncontent.ca, with a focus on simplicity and functionality. As motion designers and web designers, we collaborate closely on projects, and this website reflects our combined efforts. The clean layout showcases their work effectively while ensuring an easy and intuitive user experience.</p>
        <a href="https://crowncontent.ca/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>

        <div class="clear lg"></div>

        <img src="images/client-otn.jpg" alt="One Tiger North" class="border" />
        <div class="clear sm"></div>
        <h2 class="small">One Tiger North</h2>
        <p>One Tiger North is a boutique Pilates studio in Creemore, Ontario, offering personalized classes with Reformers and Vertical Tower Frames to help clients build strength and balance.</p>
        <p>As one of my oldest clients, I've helped brand and support their digital marketing through various rebranding phases. For their website, onetigernorth.com, I created a clean, user-friendly design that showcases their services and schedule, making it easy for clients to engage with the studio.</p>
        <a href="https://onetigernorth.com/" target="_blank" rel="noreferrer" class="btn small">Visit website</a>

        <div class="clear md"></div>

        `
    },
    {
        key: "project6",
        title: "Maker of Things",
        skills: "Illustration / Fabrication / Everything in between",
        thumb: "./images/thumb-maker.jpg",
        hero: "./images/hero-maker.jpg",
        description: `
        <span class="subtitle">Creative Design</span>
        <p class="large">Although I hold the professional title of UX/UI designer, I've never let myself be confined by these labels. Throughout my career, I've embraced a wide array of creative projects, spanning both personal and professional endeavors. These have included designing fully immersive board games and even producing captivating music videos.</p>
    
        <div class="rule"></div>
        <div class="columns">
            <div class="col two_third">
                <span class="subtitle">Creative Outlets</span>
                <p>In my personal and professional work, I've explored various creative media and mediums. Here is a selection of some of the unique and interesting projects I've enjoyed working on. I've used my designer's skills to create distinctive and engaging work.</p>
            </div>
            <div class="col one_third">
                <span class="subtitle">Projects</span>
                <p>Game Design<br />
                Promotional Merchandise<br />
                Creative Direction<br />
                Illustration</p>
            </div>
        </div>
    
        <div class="clear md"></div>
    
        <h2 class="small">The Summoning of Kramdar</h2>
    
        <p>The Summoning of Kramdar is a 2-4 player tabletop board game that I developed with my brother and business partner. The project launched on Kickstarter and was successfully funded in 2021.</p>
    
        <div class="clear sm"></div>

        <figure>
            <img src="images/kramdar-1.jpg" alt="The Summoning of Kramdar" />
            <figcaption>The Summoning of Kramdar board game parts</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/kramdar-2.jpg" alt="The Summoning of Kramdar" />
            <figcaption>Promotional graphic</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <div class="columns">
            <div class="col one_half">
                <figure>
                    <img src="images/kramdar-3.jpg" alt="The Summoning of Kramdar" />
                    <figcaption>Promotional product</figcaption>
                </figure>
            </div>
            <div class="col one_half">
                <figure>
                    <img src="images/kramdar-4.jpg" alt="The Summoning of Kramdar" />
                    <figcaption>Promotional product in use</figcaption>
                </figure>
            </div>
        </div>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/kramdar-5.jpg" alt="The Summoning of Kramdar" />
            <figcaption>Production of promotional commercial</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/kramdar-6.jpg" alt="The Summoning of Kramdar" />
            <figcaption>Behind the scenes of promotional commercial</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/kramdar-7.jpg" alt="The Summoning of Kramdar" />
            <figcaption>Booth at FanExpo Canada</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <h2 class="small">Merchandise &amp; Illustrations</h2>

        <p>I've always loved combining my passion for illustration with practical applications. Using these skills, I created enamel pin designs for local Toronto drag queens, crafted promotional clothing designs for a prominent Social Media Influencer, and even designed a graphic for a costume featured in the Netflix original series <em>The Umbrella Academy</em>.</p>
    
        <div class="clear sm"></div>

        <div class="columns">
            <div class="col one_half">
                <figure>
                    <img src="images/tucked-1.jpg" alt="Tucked Treasures" />
                    <figcaption>Merchandise design for Toronto Drag Queen</figcaption>
                </figure>
            </div>
            <div class="col one_half">
                <figure>
                    <img src="images/tucked-2.jpg" alt="Tucked Treasures" />
                    <figcaption>Merchandise design for Toronto Drag Queen in use</figcaption>
                </figure>
            </div>
        </div>
    
        <div class="clear md"></div>
    
        <div class="columns">
            <div class="col one_half">
                <figure>
                    <img src="images/tucked-3.jpg" alt="Tucked Treasures" />
                    <figcaption>Merchandise design for Toronto Drag Queen in use</figcaption>
                </figure>
            </div>
            <div class="col one_half">
                <figure>
                    <img src="images/tucked-4.jpg" alt="Tucked Treasures" />
                    <figcaption>Merchandise design for Toronto Drag Queen</figcaption>
                </figure>
            </div>
        </div>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/umbrella-1.png" alt="Umbrella Academy" />
            <figcaption>Punk band t-shirt design worn by Ritu Arya in the Umbrella Academy</figcaption>
        </figure>

        <div class="clear md"></div>
    
        <figure>
            <img src="images/umbrella-2.png" alt="Umbrella Academy" />
            <figcaption>Footage from the episode</figcaption>
        </figure>

        <div class="clear md"></div>
    
        <figure>
            <img src="images/umbrella-3.png" alt="Umbrella Academy" />
            <figcaption>Footage from the episode</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/aquascape-1.jpg" alt="Aquascape Toronto" />
            <figcaption>Promotional t-shirt design</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/aquascape-2.png" alt="Aquascape Toronto" />
            <figcaption>Promotional banding and t-shirt design</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <h2 class="small">Music Videos</h2>

        <p>Between 2012 and 2017, I had the incredible opportunity to produce a variety of music and lyric videos for a diverse range of artists. These projects included collaborations with Tegan and Sara, Buck 65, Alice in Chains, Dear Rouge, and even the legendary Celine Dion. Each project allowed me to explore unique creative directions, blending visual storytelling with music to bring their songs to life in captivating ways.</p>
    
        <div class="clear sm"></div>
    
        <figure>
            <img src="images/music-video-1.png" alt="Music Videos" />
            <figcaption>Set design - Alice In Chains music video</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/music-video-2.png" alt="Music Videos" />
            <figcaption>Set design - Alice In Chains music video</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/music-video-3.png" alt="Music Videos" />
            <figcaption>Set design - Alice In Chains music video</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/music-video-4.png" alt="Music Videos" />
            <figcaption>Effects - Dear Rouge music video</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/music-video-5.png" alt="Music Videos" />
            <figcaption>Dear Rouge - Behind the scenes</figcaption>
        </figure>
    
        <div class="clear md"></div>

        <figure>
            <img src="images/dear-rouge-bts1.jpg" alt="Music Videos" />
            <figcaption>Dear Rouge - Behind the scenes</figcaption>
        </figure>
    
        <div class="clear md"></div>

        <figure>
            <img src="images/dear-rouge-bts2.jpg" alt="Music Videos" />
            <figcaption>Dear Rouge - Behind the scenes</figcaption>
        </figure>
    
        <div class="clear md"></div>

        <figure>
            <img src="images/dear-rouge-bts3.jpg" alt="Music Videos" />
            <figcaption>Dear Rouge - Cast & crew</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/music-video-6.png" alt="Music Videos" />
            <figcaption>Production - Tegan & Sara lyric video</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/music-video-7.png" alt="Music Videos" />
            <figcaption>Production - Tegan & Sara lyric video</figcaption>
        </figure>
    
        <div class="clear md"></div>
    
        <figure>
            <img src="images/music-video-8.png" alt="Music Videos" />
            <figcaption>Production - Tegan & Sara lyric video</figcaption>
        </figure>
    
        <div class="clear md"></div>
        `
    },    
];

export const posts = [
    {
        key: "post8",
        title: "Going to Art school without being Artistic — Harsh realities about UX design",
        excerpt: "Garnering the title of “UX Designer” can significantly increase your position in the design world both financially and professionally.",
        category: "UX Design",
        read: "6 min",
        link: "https://glklee.medium.com/going-to-art-school-without-being-artistic-harsh-realities-about-ux-design-8952eba434ee",
        thumb: "./images/thumb-harsh-realities.png",
    },
    {
        key: "post7",
        title: "My Experience with Kickstarter",
        excerpt: "The trials and tribulations of running a Kickstarter campaign. Potential issues and how to avoid them.",
        category: "Crowdfunding",
        read: "13 min",
        link: "https://glklee.medium.com/our-experience-with-kickstarter-c33a2301df5a",
        thumb: "./images/thumb-kickstarter.jpg",
    },
    {
        key: "post6",
        title: "The Summoning of Kramdar",
        excerpt: "The Summoning of Kramdar is a 2 to 4 player tabletop board game that was developed by Good Lookin Kids Inc, and launched on Kickstarter.",
        category: "Creative Exploration",
        read: "15 min",
        link: "https://glklee.medium.com/the-summoning-of-kramdar-4695adb8e179",
        thumb: "./images/thumb-kramdar.jpg",
    },
    {
        key: "post5",
        title: "Designing for CSS grid using Sketch app",
        excerpt: "The best approach to designing layouts to be programmed with CSS Grid.",
        category: "UI & Development",
        read: "7 min",
        link: "https://uxdesign.cc/designing-for-css-grid-using-sketch-app-41807be1802e",
        thumb: "./images/thumb-sketch-grid.jpg",
    },
    {
        key: "post4",
        title: "A Guide to CSS Grids for Designers: Flexbox, CSS Grid, Floats & Clears",
        excerpt: "I am a strong advocate for designers to know how to develop.",
        category: "UI & Development",
        read: "6 min",
        link: "https://blog.prototypr.io/a-guide-to-css-grids-for-designers-flexbox-css-grid-floats-clears-9487659aed92",
        thumb: "./images/thumb-css-grids.jpg",
    },
    {
        key: "post3",
        title: "Developing a brand for a Toronto Aqua Hobbyist",
        excerpt: "Working with a Toronto Aquascaper, I helped develop a brand identity inspired by traditional principals and elements of design.",
        category: "Branding",
        read: "3 min",
        link: "https://glklee.medium.com/developing-a-brand-for-a-toronto-aqua-hobbyist-477908f38d77",
        thumb: "./images/thumb-aquascape.jpg",
    },
    {
        key: "post2",
        title: "Web design trends & fads for 2020",
        excerpt: "Even though we have just entered the new decade, it is easy to see some of the trends emerging in web design.",
        category: "Trends",
        read: "6 min",
        link: "https://glklee.medium.com/web-design-trends-fads-for-2020-9622f5c2dbb",
        thumb: "./images/thumb-trends.jpg",
    },
    {
        key: "post1",
        title: "Proof is in the Footer",
        excerpt: "I have designed and developed websites for some time; when searching for inspiration, or researching competitor websites, the first thing I will check is the footer.",
        category: "UI Design",
        read: "4 min",
        link: "https://glklee.medium.com/proof-is-in-the-footer-3a377c42af33",
        thumb: "./images/thumb-footer.jpg",
    },
];