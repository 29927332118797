// src/components/Header.js

export default function Footer() {
    return(
        <footer className="footer" id="contact">
            <div className="footerInner">
                <div className="plane"></div>

                <h2>Let's Craft Something Extraordinary Together!</h2>
                <a href="mailto:lee@glkinc.com">lee@glkinc.com</a>

                <ul className="social">
                    <li><a href="https://www.linkedin.com/in/lee-travaglini-30134a73/" target="_blank" rel="noreferrer"><img src="images/ico-linkedin.svg" alt="LinkedIn" width="40" height="40" /></a></li>
                    <li><a href="https://glklee.medium.com/" target="_blank" rel="noreferrer"><img src="images/ico-medium.svg" alt="Medium" width="40" height="40" /></a></li>
                    <li><a href="https://dribbble.com/glklee" target="_blank" rel="noreferrer"><img src="images/ico-dribbble.svg" alt="Dribbble" width="40" height="40" /></a></li>
                </ul>

                <div className="columns centerV">
                    <div className="col">
                        <p>&copy;2025. Lee Travaglini. All rights reserved.</p>
                    </div>
                    <div className="col">
                        <a href="https://goodlookinkids.com" className="glk" target="_blank" rel="noreferrer">A member of <strong> Good Lookin Kids inc.</strong></a>
                    </div>

                </div>
            </div>
        </footer>
    );
}